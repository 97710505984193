<template>
  <div class="sub-page-container">
    <div class="option-row">
      <Select
        v-model="searchData.farmId"
        clearable
        style="width: 200px; margin-right: 24px"
        placeholder="选择基地"
      >
        <Option v-for="farm in farms" :key="farm.id" :value="farm.id">
          {{ farm.name }}
        </Option>
      </Select>
      <Select
        v-model="searchData.deviceId"
        style="width: 200px"
        clearable
        placeholder="选择监测点"
      >
        <Option
          v-for="device in devices"
          :key="device.guid"
          :value="device.guid"
        >
          {{ device.name }}
        </Option>
      </Select>
      <!-- <span class="add-button">
        <Button type="primary">新增</Button>
      </span> -->
    </div>
    <div class="content-container">
      <div
        v-for="senor in senors"
        :key="senor.id"
        class="senor-item"
        @click="chooseSenor(senor)"
      >
        <div class="icon-span">
          <template v-if="senor.servicePath && senor.imgUrl">
            <img :src="senor.servicePath + senor.imgUrl" />
          </template>
          <template v-else>
            <img src="/imgs/empty/emptyimg.png" />
          </template>
        </div>
        <div class="info-span">
          <span>{{ senor.measure }}</span>
          <span>{{ senor.name }}({{ senor.sensorCategoryUnit }})</span>
          <span
            >更新时间:
            {{
              senor.updateTime.length > 18
                ? senor.updateTime.substring(6, 16)
                : senor.updateTime
            }}</span
          >
        </div>
      </div>
    </div>
    <div style="text-align: right">
      <Page
        @on-change="onPageChange"
        :current="page.pageNo"
        :page-size="page.pageSize"
        :total="page.total"
      ></Page>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      farms: [],
      page: {
        pageNo: 1,
        pageSize: 6,
        total: 0,
      },
      devices: [],
      searchData: {
        farmId: "",
        deviceId: "",
      },
      senors: [],
    };
  },
  watch: {
    "searchData.farmId": {
      handler() {
        this.searchData.deviceId = "";
        this.getDevice();
        this.getList();
      },
    },
    "searchData.deviceId": {
      handler() {
        this.getList();
      },
    },
  },
  methods: {
    onPageChange(pageNo) {
      this.page.pageNo = pageNo;
      this.getList();
    },
    // 查询基地
    getFarms() {
      this.$post(this.$api.BASE_MANAGE.SIMPLE_FARM_LIST).then((res) => {
        this.farms = res.list;
      });
    },
    // 查询监测点
    getDevice() {
      this.$post(this.$api.DEVICE_LL.AUTHORIZATION_LIST, {
        categoryTypes: "1,5,6",
        vestingNo: localStorage.companyNo,
        farmId: this.searchData.farmId,
      }).then((res) => {
        this.devices = res.list;
      });
    },
    // 查询列表
    getList() {
      if (this.searchData.deviceId) {
        this.getListByDevice();
      } else {
        this.getListByFarm();
      }
    },
    // 通过基地查询列表
    getListByFarm() {
      this.$post(this.$api.BUSSDEVICESENSOR.LIST, {
        farmId: this.searchData.farmId,
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
      }).then((res) => {
        this.senors = res.list;
        this.page.total = +res.total;
      });
    },
    // 通过基地查询列表
    getListByDevice() {
      let device = this.devices.find(
        (item) => item.guid == this.searchData.deviceId
      );
      this.$post(this.$api.BUSSDEVICESENSOR.LIST, {
        guidArr: device.sensorGuids,
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
      }).then((res) => {
        this.senors = res.list;
        this.page.total = +res.total;
      });
    },
    //点击展示统计图
    chooseSenor(item) {
      this.$emit("chooseSenor", item);
    },
  },
  mounted() {
    this.getFarms();
    this.getDevice();
    this.getList();
  },
};
</script>
<style lang='less' scoped>
@import url("./index.less");
</style>