<template>
  <div style="width: 100%; height: 100%; padding: 16px">
    <div
      style="
        font-size: 20px;
        font-weight: bold;
        color: #000;
        padding-bottom: 16px;
      "
    >
      网络拓扑图
    </div>
    <div class="tp-container">
      <div
        :style="{ transform: `translateX(-${currentIndex * 500}px)` }"
        class="move-container"
      >
        <div class="ht-tp">
          <animate-icon type="ht"></animate-icon>
        </div>
        <div class="hd-tp">
          <animate-icon type="hd"></animate-icon>
        </div>
        <div class="scd-tp">
          <animate-icon type="scd"></animate-icon>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import animateIcon from "./animateIcon.vue";
export default {
  components: {
    animateIcon,
  },
  data() {
    return {
      currentIndex: 0,
      toRight: true,
    };
  },
  methods: {
    //   确定展示第几个
    confirmIndex() {
      if (this.toRight) {
        if (this.currentIndex == 2) {
          this.currentIndex = 1;
          this.toRight = !this.toRight;
        } else {
          this.currentIndex += 1;
        }
      } else {
        if (this.currentIndex === 0) {
          this.currentIndex = 1;
          this.toRight = !this.toRight;
        } else {
          this.currentIndex -= 1;
        }
      }
    },
  },
  mounted() {
    let interval = setInterval(this.confirmIndex, 10 * 1000);
    this.$once("hook:beforeDestroy", () => clearInterval(interval));
  },
};
</script>
<style lang='less' scoped>
@import url("./index.less");
</style>