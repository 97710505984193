<template>
  <div style="width: 100%; height: 100%; padding: 1px">
    <div class="row-one">
      <div class="senor-list-container">
        <senor-list @chooseSenor="chooseSenor"></senor-list>
      </div>
      <div class="tuoputu-container">
        <tuoputu></tuoputu>
      </div>
    </div>
    <div class="row-two">
      <echarts-pane
        :staticsInfo="staticsInfo"
        :monitorInfo="monitorInfo"
      ></echarts-pane>
      <!-- <Tabs value="name1">
        <TabPane label="土壤温度" name="name1"> </TabPane>
        <TabPane label="空气湿度" name="name2"> </TabPane>
        <TabPane label="余氯" name="name3">标签三的内容</TabPane>
        <TabPane label="电导率2" name="name4">标签三的内容</TabPane>
        <TabPane label="PH2" name="name5">标签三的内容</TabPane>
        <TabPane label="温度" name="name6">标签三的内容</TabPane>
        <TabPane label="浊度" name="name7">标签三的内容</TabPane>
        <TabPane label="溶解氧" name="name8">标签三的内容</TabPane>
      </Tabs> -->
    </div>
  </div>
</template>
<script>
import senorList from "./senorList";
import tuoputu from "./tuoputu";
import echartsPane from "./statics/index.vue";
export default {
  components: {
    senorList,
    tuoputu,
    echartsPane,
  },
  data() {
    return {
      staticsInfo: [],
      monitorInfo: {
        updateTime: "",
        count: 0,
        unit: "",
        reference: "",
        type: "",
      },
    };
  },
  methods: {
    chooseSenor(val) {
      this.monitorInfo = {
        updateTime: val.updateTime,
        count: val.measure,
        unit: val.sensorCategoryUnit,
        type: val.name,
        reference: val.categoryMinRange + "~" + val.categoryMaxRange,
      };
      this.$post(this.$api.MONITOR.MONITOR_CHART_NEW, {
        switchType: "day",
        guid: val.guid,
      }).then((res) => {
        this.staticsInfo = res[0].child;
      });
    },
  },
};
</script>
<style lang='less' scoped>
@import url("./index.less");
</style>